.contacts{
    padding: 1.5em 0 3em;  
    //background-color: #a4c3de;;
    background-image: url(../img/expertise-bg.jpg);     

    &__content{
        margin: 0 auto;
        padding: 0 0.6em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__heading{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 2.2em;
        text-transform: capitalize;        
        margin: 0.5em 0 1em;
        text-align: center;
        color: #42486d;

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            font-size: 3em; 
            line-height: 1.25em;
            margin-bottom: 0.3em;
        }
        
        @media screen and (min-width: $bp-tablet) {
            font-size: 3.5em;
            margin-bottom: 0.5em; 
        }   
    }

    &__description{
        font-size: 1.2em;
        line-height: 1.25em;
        text-align: center;
        margin-bottom: 1em;
        max-width: 270px;

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            max-width: 460px;
        }
        
        @media screen and (min-width: $bp-tablet) {
            max-width: 800px;
        }   
    }

    &__form{
        @include flex-column; 

        .feedback-input{
            font-family: Montserrat;
            font-style: normal;
            color: #42486d;
            font-size: 1em;
            line-height: 1.25em;
            margin-bottom: 1em;
            max-width: 570px;
            padding: 0.2em;
            min-width: 300px;
            padding: 10px;
            border-radius: 10px;
            border-width: 0.5px;
        }

        #message {
            resize: vertical;
            min-height: 20px; 
            max-height: 120px;
        }

        .btn-submit{
            background-color: $is-colored;
            text-decoration: none;
            font-weight: 700;
            max-width: 100%;
            min-width: 150px;
            padding: 15px;
            color: white;
            border-radius: 30px;
            border: 1px solid $is-colored;
        }

        .btn-submit:hover {
            background-color: #fff;
            color: $is-colored;
            cursor: pointer;
        }

        .btn-submit:active {
            -webkit-transform: scale(1.03);
            transform: scale(1.03);
        }

        .btn-submit.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__afterwords {
        font-family: 'Lato';
        color: #42486d;
        font-size: 0.87em;
        line-height: 1.2em;
        padding-top: 60px;
        position: relative;
        max-width: 870px;
        margin: 0 auto;
        text-align: center;
    }

    &__afterwords::after {
        background-color: #42486d;
        content: '';
        position: absolute;
        height: 2px;
        width: 90%;
        top: calc(100% + 14px);
        right: 5%;
    }    

    #responseMessage {
        border-radius: 5px;
        color: #42486d;
        font-size: 20px;
        text-align: center;
        margin: 0 auto 1em;
      }
      
      #responseMessage.success {
        color: #42486d; 
      }
      
      #responseMessage.error {
        color: #c23636;
      }
      
      #responseMessage.show {
        opacity: 1;
      }

      .hide {
        opacity: 0;
      }
    
      #contactFormWrapper {
        min-height: 240px;
        
        p {
            margin: 10% auto 0;
            color: #42486d; 
            font-size: 2em;
            line-height: 1.25em;
            text-align: center;
        }
      }
}