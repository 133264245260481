.intro {
    background-image: url(../img/main_pic.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Montserrat';
    text-align: center;
    height: 900px;
    position: relative;
    margin-bottom: 30px;
    

    &__headline {
        color: #ffffff;
        font-size: 4.5em;
        padding-top: 163px;
        font-weight: 700;
    }

    &__description {
        font-family: 'Lato';
        color: $is-colored;
        font-size: 1.8em;
        padding-top: 21px; 
        line-height: 1.4;  
    }
    
}

@media screen and (max-width: $bp-tablet) {
    .intro {
        &__headline {
            padding-top: 150px;
            font-size: 72px;
        }

        &__description {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: $bp-mobile) {
    .intro {

        height: 800px;

        &__headline {
            font-size: 48px;
        }

        &__description {
            font-size: 18px;
        }

        &__text {
            font-size: 14px;
        }
    }
}