footer {
    background-color: #000000;
    color: $is-colored;
    font-family: Montserrat, Lato;
    position: relative;
}

.center-holder {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 10px;
}

.copyright {
    font-size: 1.2em;
    line-height: 1.25em;
    text-align: center;
    //margin-bottom: 1em;
    padding: 1em;
}

@media screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-tablet) {
    // put some styles here
}