.portfolio{
    font-family: Montserrat;
    padding: 0 0 4em;
    background: url(../img/Layr21.png) no-repeat;
    background-size: cover;
    color: #42486d;
    
    @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
        padding: 5em 0 7em;
    }
    
    @media screen and (min-width: $bp-tablet) {
        padding: 5em 0 9em;
    } 

    &__content{
        margin: 0 auto;
        padding: 0 0.6em;
        @include flex-column;                  
    }

    &__heading{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 1.75em;       
        text-transform: capitalize;
        text-align: center;
        line-height: 1.5em;
        margin-bottom: 2em;
        width: 297px;        

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            font-size: 2.6em; 
            line-height: 1.25em;
            margin-bottom: 1em;
            width: 100%;
        }
        
        @media screen and (min-width: $bp-tablet) {
            font-size: 3em; 
            width: 100%;
        } 
    }

    &__plans {      
        display: grid;
        gap: 2em 1em;        
        margin: 0 auto;
        color: #42486d;
        justify-items: center;      

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            display: grid;
            gap: 5em 1em;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 460px);
        }
        
        @media screen and (min-width: $bp-tablet) {
            display: flex;
            justify-content: space-between; 
            align-items: center; 
            margin: 0 auto;                
        }        
        
        .plan {
            font-weight: lighter;
            background: rgba(255, 255, 255, 0.51);
            width: 256px;
            height: 457px;            
            margin: 0 auto;
            transition: transform 1s;
            transition: margin 1s;         

            &__heading{
                background: rgba(255, 255, 255, 0.06);
                font-size: 1.5em;
                font-weight: lighter;
                text-transform: uppercase;
                text-align: center;
                padding: 1.5em 1.3em;                    
            }

            &__details{
                display: flex;
                flex-direction: column; 
                align-items: center;
                padding: 2em 0;
            }

            &__price{
                display: flex;
                flex-direction: column; 
                align-items: center;
                text-transform: uppercase;
            }

            &__price::after{
                display: block;
                background: #42486d;                
                margin: 2em 0;
                content: " ";
                width: 60px;
                height: 5px;
            }         

            &__price-amount{
                font-size: 3.85em;
                font-weight: bold;
                margin-bottom: 0.5em;
            }

            &__price-currency{
                font-weight: normal;
            }

            &__licensing{
                display: flex;
                flex-direction: column; 
                align-items: center;
                line-height: 1.5em;
                margin-bottom: 2em;
                height: 48px;
            }

            &__buy-button{
                text-transform: uppercase;
                color: white;
                background: rgba(255, 255, 255, 0.151);
                border: 1px solid transparent;
                border-radius: 40px;
                width: 163px;
                height: 45px;
            }
        }

        .plan.active .plan__heading{
            background: rgba(0, 0, 0, 0.11);
        }

        .plan.active {
            background: #42486d;
            margin: 2em;
            transform: scale(1.2);  
            transition: margin 3s;             
            transition: transform 1s;  
            color:white;
            
            @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){
                margin: 0 1em;
            }

            @media screen and (min-width: $bp-tablet) {
                margin: 0 1.4em;
            } 
        }        
    }      
}