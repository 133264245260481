@import "../../node_modules/reset-css/sass/reset";
@import "_variables";
@import "_mixins";
@import "_reset";
@import "sections/_header";
@import "sections/_intro";
@import "sections/_about";
@import "sections/_specialities";
@import "sections/_why-us";
@import "sections/_services";
@import "sections/_expertise";
@import "sections/_portfolio";
@import "sections/_contacts";
@import "sections/_footer";
@import "_fonts";


body {
    font-family: 'Lato', 'Montserrat', sans-serif;
    font-weight: 400;
    min-width: 320px;
    width: 100%;
}

.wrapper {
    overflow: hidden;
} 

html { scroll-behavior: smooth; }