.specialities_headline {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    //color: #42486d;
    color: white;
}

.specialities {
    color: #fff;
    //background-image: url(../img/expertise-bg.jpg);
    background-color: rgb(61, 67, 105);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Montserrat';
    text-align: center;
    padding: 5em 0 2em;

    &__container {
        padding: 5em 0 3em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3em;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255, 255, 255);
        padding: 2em;
        border-radius: 10px;
       
        h3 {
            //margin: 0 auto 0.9em auto;
            font-family: 'Lato';
            font-weight: 600;
            font-size: 1.3em;
            line-height: 24px;
            color:rgb(61, 67, 105);
        }

        img {
            margin: 0 auto 0.5em auto;
            object-fit: cover;
            max-height: 50px;
        }

        p {
            font-family: 'Lato';
            font-style: italic;
            font-weight: 500;
            font-size: 1em;
            line-height: 24px;
            color: #42486db1;
        }
    }

}

@media screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-tablet) {

    .specialities_headline {
        font-size: 2.6em;
    }

    .specialities {
        padding: 6em 1em 1em;
        background-color: rgb(61, 67, 105);
        color: white;

        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 25px;
            padding: 3em 0 3em 0;
        }

        &__afterwords {
            padding: 4em 0 1em;
        }

        &__afterwords::after {
            top: calc(100% - 4px);
        }
    }
}

@media screen and (max-width: $bp-mobile) {
    .specialities_headline {
        font-size: 2.25em;
    }

    .specialities {
    padding: 5.5em 0.5em 3em;
    background-color: rgb(61, 67, 105);
    color: white;

    &__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 45px;
        padding-top: 3em;
    }

    &__afterwords {
        display: none;
    }
}
}