header {
    background-color: #000000;
    color: $is-colored;
    font-family: Montserrat, Lato;
    position: relative;
}

.center-holder {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 10px;
}

.navigation-container {
    padding: 30px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .center-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.logo {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logo__text {
    padding-left: 17px;
}

.logo__text {
    font-family: 'Lato';
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
}

.logo__icon {
    display: block;
    background-image: url('../img/logo-pencil.png');
    min-width: 50px;
    min-height: 44px;
}

.logo:hover {
    .logo__icon {
        background-image: url('../img/logo-pencil-hover.png');
        min-width: 50px;
        min-height: 44px;
    }

    .logo__text {
        color: #fff;
    }
}

.menu {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 25px;

    &__list {
        display: flex;
        list-style: none;
        align-items: center;
    }

    &__item {
        margin-right: 38px;
    }

    &__item:last-child {
        margin-right: 0;
    }

    &__link {
        text-decoration: none;
        color: inherit;
        font-weight: 700;
        font-family: 'Montserrat';
    }

    &__link:hover {
        color: #fff;
    }
}

.button__header {
    @include flex-center;
    background-color: $is-colored;
    text-decoration: none;
    font-weight: 700;
    max-width: 100%;
    padding: 15px 0;
    color: white;
    border-radius: 40px;
    border: 1px solid $is-colored;
}

.button-buy {
    min-width: 145px;
}

.button-buy:hover {
    background-color: #fff;
    color: $is-colored;
}

.button-contact-dark {
    min-width: 145px;
}

.button-contact-dark:hover {
    background-color: #fff;
    color: $is-colored-dark;
}

.button-download-holder {
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
}

.intro__text-trial {
    padding-top: 28px;
    color: #848E97;
}

.button-download {
    font-size: 1.31em;
    padding: 20px 0;
    width: 390px;
}

.button-download:hover {
    background-color: #fff;
    color: $is-colored;
}

@media screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-tablet) {
    .navigation-container {
        background-color: #27262A;
        padding: 18px 0;

        .center-holder {
            flex-wrap: wrap;
        }
    }

    .logo {
        order: 1;
    }

    .menu {
        order: 3;

        &__list {
            width: 100%;
            justify-content: space-around;
            padding: 21px 0 0;
        }

        &__link {
            // padding-right: 15px;
            font-size: 0.88em;
        }

        &__item {
            margin-right: 19px;
        }
    }

    .button__header {
        order: 2;
    }
}

.burger-menu-wrap {
    font: inherit;
    display: inline-block;
    overflow: visible;
    padding: 13px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
}

.burger-menu {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 15px;
}

.burger-menu-inner {
    top: 0px;
    width: 13px;
    display: block;
}

.burger-menu-inner,
.burger-menu-inner:after,
.burger-menu-inner:before {
    position: absolute;
    width: 26px;
    height: 3px;
    right: 0;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;

}

.burger-menu-inner {
    width: 13px;
}

.burger-menu-inner:before {
    top: 7px;
    width: 26px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform, opacity;
}

.burger-menu-inner:after,
.burger-menu-inner:before {
    display: block;
    content: "";
}

.burger-menu-inner:after {
    top: 15px;
}

.burger-menu-wrap.active .burger-menu-inner:before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
}

.burger-menu-wrap.active .burger-menu-inner,
.burger-menu-wrap.active .burger-menu-inner:after,
.burger-menu-wrap.active .burger-menu-inner:before {
    background-color: #fff;
}

.burger-menu-wrap.active .burger-menu-inner:after {
    transform: translate3d(0, -15px, 0) rotate(-90deg);
}

.burger-menu-wrap.active .burger-menu-inner {
    transform: translate3d(0, 15px, 0) rotate(45deg);
    width: 24px;
}

@media screen and (min-width: $bp-mobile + 1px) {
    .burger-menu-wrap {
        display: none;
    }
}

@media screen and (max-width: $bp-mobile) {

    .navigation-container {
        background-color: #27262A;
        padding: 22px 0;
    }

    .logo {
        order: 1;

        &__text.hidden {
            display: none;
        }
    }

    .button__header {
        order: 2;
    }

    .button-buy {
        width: 120px;
    }

    .menu {
        position: relative;
        text-align: right;
        padding: 15px 0 15px;
        order: 3;
        width: inherit;

        &__list {
            transition: .3s ease-in-out;
            position: absolute;
            top: 77px;
            width: 300px;
            background: #27262A;
            // right: 15px;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 20px 0px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
        }

        &__item {
            padding: 21px 30px;
            text-align: left;
            width: 100%;
            border-bottom: 1px solid grey;
            margin-right: 0px;
            color: white;
        }

        &__link {
            font-weight: 400;
            font-size: 26px;
            position: relative;
            padding-left: 15px;
        }

        &__link:hover {
            color: #8D81AC;
        }

        &__link:hover::before {
            content: '';
            left: -29px;
            top: -9px;
            width: 8px;
            height: 54px;
            background: #8D81AC;
            position: absolute;
        }
    }

    .button-download {
        font-size: 18px;
        padding: 15px 0;
        max-width: 290px;
    }

    .burger-menu-wrap {
        &.active~.menu__list {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}