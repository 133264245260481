.services_headline {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    color: white;

}

.services {
    color: #fff;
    background-color: rgb(61, 67, 105);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Montserrat';
    text-align: center;
    padding: 5em 0 2em;

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2em;
        align-items: start;
        justify-items: start;
        padding: 4em 0 1em;
        margin: 0 auto;
        max-width: 780px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: white;

        h3 {
            margin: 0 auto 0.9em auto;
            font-family: 'Lato';
            font-weight: 500;
            font-size: 1.3em;
            line-height: 24px;
            color: white;
        }

        h3::before {
            content: '-';
            color: white;
            margin: 0 0.5em;
        }

        img {
            margin: 0 auto 0.5em auto;
            object-fit: cover;
            max-height: 50px;
        }

        p {
            font-family: 'Lato';
            font-style: italic;
            font-weight: 500;
            font-size: 1em;
            line-height: 24px;
            color: #42486db1;
        }
    }

}

@media screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-tablet) {

    .services_headline {
        font-size: 2.6em;
    }

    .services {
        padding: 4em 0 1em 0;
        background-color: rgb(61, 67, 105);
        color: white;

        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 25px;
            padding: 3em 0 1em 1%;
            align-items: start;
            justify-items: start;
        }

        &__afterwords {
            padding: 4em 0 1em;
        }

        &__afterwords::after {
            top: calc(100% - 4px);
        }
    }
}

@media screen and (max-width: $bp-mobile) {
    .services_headline {
        font-size: 2.25em;
    }

    .services {
        padding: 5em 0 4em;
        background-color: rgb(61, 67, 105);
        color: white;

        &__container {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;
            padding: 3.6em 0 0 1.5em;
        }

        &__afterwords {
            display: none;
        }
    }
}