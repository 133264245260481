.expertise_headline {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    color: #42486d;
}

.expertise {
    color: #fff;
    //background-image: url(../img/expertise-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Montserrat';
    text-align: center;
    padding: 5em 0 2em;
    // height: 790px;

    &__container {
        padding: 5em 0 1em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5em;
    }

    &__item {
        // display: grid;
        // grid-template-rows: 50px 1fr;
        // gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        h3 {
            margin: 0 auto 0.9em auto;
            font-family: 'Lato';
            font-weight: 800;
            font-size: 1.3em;
            line-height: 24px;
            color: #42486dec;
        }

        img {
            margin: 0 auto 0.5em auto;
            object-fit: cover;
            max-height: 50px;
        }

        p {
            font-family: 'Lato';
            font-style: italic;
            font-weight: 500;
            font-size: 1em;
            line-height: 24px;
            color: #42486db1;
        }
    }

}

@media screen and (min-width: $bp-mobile + 1px) and (max-width: $bp-tablet) {

    .expertise_headline {
        font-size: 2.6em;
        // padding-top: 1.8em;
    }

    .expertise {
        padding: 4em 0 1em;

        &__container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 25px;
        }

        &__afterwords {
            padding: 4em 0 1em;
        }

        &__afterwords::after {
            top: calc(100% - 4px);
        }
    }
}

@media screen and (max-width: $bp-mobile) {
    .expertise_headline {
        font-size: 2.25em;
    }

    .expertise {
    padding: 3.5em 0 8em;

    &__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 45px;
        padding-top: 3.6em;
    }


    &__afterwords {
        display: none;
    }
}
}