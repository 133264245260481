@mixin tablet-width() {
  @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
      width: 768px;  
  }
}

@mixin desktop-width() {
  @media screen and (min-width: $bp-tablet) {
    width: 1200px; 
  }
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
  justify-items: center;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
  align-items: center;  
}


@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
