.about{
    padding: 1.5em 0 3em;
    position: relative;       

    &__content{
        margin: 0 auto;
        padding: 0 0.6em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__heading{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 1.6em;        
        text-transform: uppercase;
        margin-bottom: 0.5em;
        text-align: center;
        color: #42486d;

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            font-size: 2.6em; 
            line-height: 1.25em;
            margin-bottom: 0.3em;
        }
        
        @media screen and (min-width: $bp-tablet) {
            font-size: 3.5em;
            margin-bottom: 0.3em; 
        }   
    }

    &__description{
        font-size: 1.2em;
        line-height: 1.25em;
        text-align: center;
        margin-bottom: 1em;
        max-width: 270px;

        @media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet - 1px ){        
            max-width: 660px;
        }
        
        @media screen and (min-width: $bp-tablet) {
            max-width: 800px;
        }   
    }
}