
@mixin font_face($file_name, $font_name: $file_name, $path: $font_path, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font_name);
        src: url($path + $file-name + ".woff") format("woff");
        font-weight: $weight;
        font-style: $style;
    }
}

$font_path: '../fonts/';

@include font_face("Lato-Regular", "Lato", $font_path, 400);
@include font_face("Lato-Bold", "Lato", $font_path, 700);
@include font_face("Montserrat-Bold", "Montserrat", $font_path, 700);
@include font_face("Montserrat-Regular", "Montserrat", $font_path, 400);
